/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    pre: "pre",
    code: "code",
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<DataList\n\theader={[\n\t\t<DataList.HeaderCell key={0} name=\"id\" content=\"Contact ID\" />,\n\t\t<DataList.HeaderCell key={1} name=\"desc\" content=\"Description\" />,\n\t\t<DataList.HeaderCell key={2} name=\"employee\" content=\"Employee\" />\n\t]}\n\titems={[\n\t\t{id: \"987ABCD\", desc: \"1\"},\n\t\t{id: \"654ABCD\", desc: \"2\"},\n\t\t{id: \"643ABCD\", desc: \"3\"}\n\t].map(i => ({...i, employee:\n\t\t<AnvilSelect\n\t\t\toptions={[{text: 'John Doe'}, {text: 'Mandy Howard'}, {text: 'Aditya Gupta'}]}\n\t\t\ttrigger={{placeholder: 'Technician', size: 'small'}}\n\t\t/>\n\t}))}\n/>\n")), "\n", React.createElement(_components.h1, {
    id: "spacing",
    style: {
      position: "relative"
    }
  }, "Spacing", React.createElement(_components.a, {
    href: "#spacing",
    "aria-label": "spacing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "For more compact layouts, spacing between Data List rows can be condensed."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<DataList\n\tspacing={1}\n\theader={[\n\t\t<DataList.HeaderCell key={0} name=\"id\" content=\"Contact ID\" />,\n\t\t<DataList.HeaderCell key={1} name=\"desc\" content=\"Description\" />,\n\t\t<DataList.HeaderCell key={2} name=\"employee\" content=\"Employee\" />\n\t]}\n\titems={[\n\t\t{id: \"987ABCD\", desc: \"1\"},\n\t\t{id: \"654ABCD\", desc: \"2\"},\n\t\t{id: \"643ABCD\", desc: \"3\"}\n\t].map(i => ({...i, employee:\n\t\t<AnvilSelect\n\t\t\toptions={[{text: 'John Doe'}, {text: 'Mandy Howard'}, {text: 'Aditya Gupta'}]}\n\t\t\ttrigger={{placeholder: 'Technician', size: 'small'}}\n\t\t/>\n\t}))}\n/>\n")), "\n", React.createElement(_components.h1, {
    id: "simpler-look",
    style: {
      position: "relative"
    }
  }, "Simpler Look", React.createElement(_components.a, {
    href: "#simpler-look",
    "aria-label": "simpler look permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "For table with transparent background and border only on the header."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<DataList\n\tsimple\n\theader={[\n\t\t<DataList.HeaderCell key={0} name=\"id\" content=\"Contact ID\" />,\n\t\t<DataList.HeaderCell key={1} name=\"desc\" content=\"Description\" />,\n\t\t<DataList.HeaderCell key={2} name=\"employee\" content=\"Employee\" />\n\t]}\n\titems={[\n\t\t{id: \"987ABCD\", desc: \"1\"},\n\t\t{id: \"654ABCD\", desc: \"2\"},\n\t\t{id: \"643ABCD\", desc: \"3\"}\n\t].map(i => ({...i, employee:\n\t\t<AnvilSelect\n\t\t\toptions={[{text: 'John Doe'}, {text: 'Mandy Howard'}, {text: 'Aditya Gupta'}]}\n\t\t\ttrigger={{placeholder: 'Technician', size: 'small'}}\n\t\t/>\n\t}))}\n/>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { DataList } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
